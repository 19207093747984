<template>
  <div>
    <navbar v-if="shownavandfooter === true"></navbar>
    <v-container fluid class="mt-4 mb-4">
      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>

      <v-row class="d-flex text-main justify-center">
        <v-col :cols="shownavandfooter === true ? '11' : '12'">
          <v-data-table :headers="headers" :items="products" :loading="loading" :search="search" sort-by="name" :class="shownavandfooter === true
            ? 'elevation-1 nzar-font radius-15'
            : 'nzar-font radius-15'
            " :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><v-icon>mdi-shopping</v-icon>
                  {{ langkeyword("PRODUCTS") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success"
                      :style="permissionExist('#add_products') ? 'color: white;float:right' : 'display:none;'"
                      class="mb-2 radius-5 nzar-font" v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("ADD") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>

                          <v-img v-if="CURRENTFORM === 'edit'" width="100%" height="100%" class="mx-auto rounded-lg"
                            :src="uploadPath(productsObject.avatar)">
                          </v-img>

                          <v-file-input v-if="CURRENTFORM != 'changeqty'" v-model="productsObject.avatar" id="avatar"
                            name="avatar" required accept="image/png, image/jpeg, image/bmp" prepend-icon="mdi-camera"
                            :label="langkeyword('IMAGE')">
                          </v-file-input>


                          <v-text-field v-if="isChangeQty() != true" class="nzar-font" append-icon="mdi-format-title"
                            v-model="productsObject.ku_name" :label="langkeyword('NAME')">
                          </v-text-field>
                          <v-text-field v-if="isChangeQty() != true" class="d-none nzar-font"
                            append-icon="mdi-format-title" v-model="productsObject.ar_name"
                            :label="langkeyword('AR_NAME')">
                          </v-text-field>
                          <v-text-field v-if="isChangeQty() != true" class="d-none nzar-font"
                            append-icon="mdi-format-title" v-model="productsObject.en_name"
                            :label="langkeyword('EN_NAME')">
                          </v-text-field>

                          <v-text-field v-if="isChangeQty() != true" class="nzar-font nzar-font"
                            append-icon="mdi-barcode" v-model="productsObject.barcode" :label="langkeyword('BARCODE')">
                          </v-text-field>

                          <v-select v-if="isAdd() === true || isEdit() === true"
                            v-model="productsObject.productcategory_id" :items="productcategories"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('PRODUCTCATEGORY')" dense></v-select>

                          <v-select class="d-none" v-if="isAdd() === true || isEdit() === true"
                            v-model="productsObject.brand_id" :items="brands"
                            :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('BRAND')" dense></v-select>

                          <v-select v-if="isAdd() === true || isEdit() === true" v-model="productsObject.unit_one"
                            :items="units" :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('UNITONE')" dense></v-select>

                          <v-select v-if="isAdd() === true || isEdit() === true" v-model="productsObject.unit_many"
                            :items="units" :item-text="langoptions('value') + '_name'" item-value="id" clearable
                            :label="langkeyword('UNITMANY')" dense></v-select>

                          <v-text-field v-if="isChangeQty() != true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.unit_one_from_many" :label="langkeyword('UNITONEFROMMANY')">
                          </v-text-field>

                          <v-text-field v-if="isChangeQty() != true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.buy_price_iqd" :label="langkeyword('BUYPRICE') +
                              ' ــ ' +
                              langkeyword('IQD')
                              ">
                          </v-text-field>

                          <v-text-field v-if="isChangeQty() != true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.buy_price_usd" :label="langkeyword('BUYPRICE') +
                              ' ــ ' +
                              langkeyword('USD')
                              ">
                          </v-text-field>

                          <v-text-field v-if="isChangeQty() != true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.sale_price_iqd" :label="langkeyword('SALEPRICE') +
                              ' ــ ' +
                              langkeyword('IQD')
                              ">
                          </v-text-field>

                          <v-text-field v-if="isChangeQty() != true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.sale_price_usd" :label="langkeyword('SALEPRICE') +
                              ' ــ ' +
                              langkeyword('USD')
                              ">
                          </v-text-field>


                          <v-text-field v-if="isChangeQty() == true" type="number" min="0" step="any" class="nzar-font"
                            v-model="productsObject.qty" :label="langkeyword('QUANTITY')">
                          </v-text-field>


                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveProducts">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark
                        @click="updateProducts(productsObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isChangeQty() === true" dark
                        @click="updateQty(productsObject.id)">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="1000px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="deleteProducts(productsObject.id)">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12" md="10">
                    <v-text-field v-if="permissionExist('#search_products')" class="nzar-font" v-model="search"
                      append-icon="mdi-magnify" :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>

            <template v-slot:[`item.avatar`]="{ item }">
              <v-img width="70px" height="70px" class="mx-auto rounded-lg" :src="uploadPath(item.avatar)"></v-img>
            </template>

            <template v-slot:[`item.edit`]="{ item }">
              <v-icon v-if="permissionExist('#edit_products')" small class="mr-2 primary white--text px-2 py-2 rounded"
                @click="changeQtyDialog(item)">
                mdi-package-variant-plus
              </v-icon>
              <v-icon v-if="permissionExist('#edit_products')" small class="mr-2 orange white--text px-2 py-2 rounded"
                @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon v-if="permissionExist('#delete_products')" small class="mr-2 red white--text px-2 py-2 rounded"
                @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter v-if="shownavandfooter === true" />
  </div>
</template>

<script>
import Vue from "vue";
import ProductsRequests from "../../../requests/dashboard/product/Products";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  props: {
    shownavandfooter: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    snackbar: true,
    products: [],
    search: "",
    loading:
      Vue.prototype.getters().getproducts == null ||
        Vue.prototype.getters().getproducts == "" ||
        Vue.prototype.getters().getproducts == undefined
        ? true
        : false,
    error_msg: null,
    success_msg: null,
    headers: [
      { text: "#", value: "id", align: "center" },
      {
        text: Vue.prototype.langkeyword("NAME"),
        value: Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text:
          Vue.prototype.langkeyword("BUYPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("USD"),
        value: "buy_price_usd",
        align: "center",
      },
      {
        text:
          Vue.prototype.langkeyword("BUYPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("IQD"),
        value: "buy_price_iqd",
        align: "center",
      },
      {
        text:
          Vue.prototype.langkeyword("SALEPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("USD"),
        value: "sale_price_usd",
        align: "center",
      },
      {
        text:
          Vue.prototype.langkeyword("SALEPRICE") +
          " ــ " +
          Vue.prototype.langkeyword("IQD"),
        value: "sale_price_iqd",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("QUANTITY"),
        value: "qty",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("IMAGE"),
        value: "avatar",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("BARCODE"),
        value: "barcode",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("PRODUCTCATEGORY"),
        value:
          "productcategories_" + Vue.prototype.langoptions("value") + "_name",
        align: "center",
      },
      {
        text: Vue.prototype.langkeyword("ACTIONS"),
        value: "edit",
        sortable: false,
        align: "center",
      },
    ],
    CURRENTFORM: "add",
    productsObject: {
      user_id: Vue.prototype.dashboardAuthId(),
      avatar: null,
      ku_name: '',
      ar_name: '',
      en_name: '',
      barcode: null,
      qty: 0,
      buy_price_usd: 0,
      buy_price_iqd: 0,
      sale_price_usd: 0,
      sale_price_iqd: 0,
      unit_one: 1,
      unit_many: 1,
      productcategory_id: '',
      brand_id: '',
      unit_one_from_many: 1,
    },
  }),
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
  },
  computed: {
    units() {
      return this.$store.getters.getunits;
    },
    productcategories() {
      return this.$store.getters.getproductcategories;
    },
    brands() {
      return this.$store.getters.getbrands;
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    isChangeQty() {
      if (this.CURRENTFORM === "changeqty") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      this.productsObject = {
        user_id: Vue.prototype.dashboardAuthId(),
        avatar: null,
        ku_name: '',
        ar_name: '',
        en_name: '',
        barcode: null,
        qty: 0,
        buy_price_usd: 0,
        buy_price_iqd: 0,
        sale_price_usd: 0,
        sale_price_iqd: 0,
        unit_one: 1,
        unit_many: 1,
        productcategory_id: '',
        brand_id: '',
        unit_one_from_many: 1,
      };
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: '',
          ar_name: '',
          en_name: '',
          barcode: null,
          qty: 0,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: '',
          brand_id: '',
          unit_one_from_many: 1,
        },
        item
      );
      this.dialog = true;
    },
    changeQtyDialog(item) {
      this.CURRENTFORM = "changeqty";
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: '',
          ar_name: '',
          en_name: '',
          barcode: null,
          qty: 0,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: '',
          brand_id: '',
          unit_one_from_many: 1,
        },
        item
      );
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: '',
          ar_name: '',
          en_name: '',
          barcode: null,
          qty: 0,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: '',
          brand_id: '',
          unit_one_from_many: 1,
        },
        item
      );
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.productsObject = Object.assign(
        {
          user_id: Vue.prototype.dashboardAuthId(),
          avatar: null,
          ku_name: '',
          ar_name: '',
          en_name: '',
          barcode: null,
          qty: 0,
          buy_price_usd: 0,
          buy_price_iqd: 0,
          sale_price_usd: 0,
          sale_price_iqd: 0,
          unit_one: 1,
          unit_many: 1,
          productcategory_id: '',
          brand_id: '',
          unit_one_from_many: 1,
        },
        item
      );
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.productsObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            avatar: null,
            ku_name: '',
            ar_name: '',
            en_name: '',
            barcode: null,
            qty: 0,
            buy_price_usd: 0,
            buy_price_iqd: 0,
            sale_price_usd: 0,
            sale_price_iqd: 0,
            unit_one: 1,
            unit_many: 1,
            productcategory_id: '',
            brand_id: '',
            unit_one_from_many: 1,
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.productsObject = Object.assign(
          {
            user_id: Vue.prototype.dashboardAuthId(),
            avatar: null,
            ku_name: '',
            ar_name: '',
            en_name: '',
            barcode: null,
            qty: 0,
            buy_price_usd: 0,
            buy_price_iqd: 0,
            sale_price_usd: 0,
            sale_price_iqd: 0,
            unit_one: 1,
            unit_many: 1,
            productcategory_id: '',
            brand_id: '',
            unit_one_from_many: 1,
          },
          this.defaultItem
        );
        this.CURRENTFORM = "add";
      });
    },
    readData() {
      this.products = this.$store.getters.getproducts;
      setTimeout(() => {
        if (!this.permissionExist("#view_products")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
    saveProducts() {
      this.validate(
        [
          this.productsObject.ku_name,
          this.productsObject.unit_one,
          this.productsObject.unit_many,
          this.productsObject.productcategory_id,
        ],
        () => {
          const avatarname = document.getElementById('avatar').files[0]
          const addParams = new FormData();
          addParams.append('addProducts', true)
          addParams.append('ku_name', this.productsObject.ku_name)
          addParams.append('ar_name', this.productsObject.ku_name)
          addParams.append('en_name', this.productsObject.ku_name)
          addParams.append('barcode', this.productsObject.barcode)
          addParams.append('buy_price_usd', this.productsObject.buy_price_usd)
          addParams.append('buy_price_iqd', this.productsObject.buy_price_iqd)
          addParams.append('sale_price_usd', this.productsObject.sale_price_usd)
          addParams.append('sale_price_iqd', this.productsObject.sale_price_iqd)
          addParams.append('unit_one', this.productsObject.unit_one)
          addParams.append('unit_many', this.productsObject.unit_many)
          addParams.append('unit_one_from_many', this.productsObject.unit_one_from_many)
          addParams.append('productcategory_id', this.productsObject.productcategory_id)
          addParams.append('brand_id', this.productsObject.brand_id)
          addParams.append('avatar', avatarname)
          addParams.append('user_id', Vue.prototype.dashboardAuthId())
          const config = {
            headers: {
              'content-type': `multipart/form-data; boundary=${addParams._boundary}`,
            }
          }
          ProductsRequests.add(addParams, config,
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.data;
                this.products = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateProducts(productId) {
      this.validate(
        [
          this.productsObject.ku_name,
          this.productsObject.unit_one,
          this.productsObject.unit_many,
          this.productsObject.productcategory_id,
        ],
        () => {
          const avatarname = document.getElementById('avatar').files[0]
          const editParams = new FormData();
          editParams.append('editProducts', true)
          editParams.append('product_id', productId)
          editParams.append('ku_name', this.productsObject.ku_name)
          editParams.append('ar_name', this.productsObject.ku_name)
          editParams.append('en_name', this.productsObject.ku_name)
          editParams.append('barcode', this.productsObject.barcode)
          editParams.append('buy_price_usd', this.productsObject.buy_price_usd)
          editParams.append('buy_price_iqd', this.productsObject.buy_price_iqd)
          editParams.append('sale_price_usd', this.productsObject.sale_price_usd)
          editParams.append('sale_price_iqd', this.productsObject.sale_price_iqd)
          editParams.append('unit_one', this.productsObject.unit_one)
          editParams.append('unit_many', this.productsObject.unit_many)
          editParams.append('unit_one_from_many', this.productsObject.unit_one_from_many)
          editParams.append('productcategory_id', this.productsObject.productcategory_id)
          editParams.append('brand_id', this.productsObject.brand_id)
          editParams.append('avatar', avatarname)
          editParams.append('user_id', Vue.prototype.dashboardAuthId())
          const config = {
            headers: {
              'content-type': `multipart/form-data; boundary=${editParams._boundary}`,
            }
          }
          ProductsRequests.edit(editParams, config,
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.data;
                this.products = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateQty(productId) {
      this.validate(
        [
          this.productsObject.qty,
        ],
        () => {
          ProductsRequests.changeQty(
            {
              changeQtyProducts: true,
              product_id: productId,
              qty: this.productsObject.qty,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.$store.state.products = res.data.data;
                this.products = res.data.data;
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteProducts(productId) {
      ProductsRequests.delete(
        { deleteProducts: true, product_id: productId },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.$store.state.products = res.data.data;
            this.products = res.data.data;
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
  },
};
</script>
