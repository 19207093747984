<template>
    <div>
        <v-container fluid class="mb-4">
            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto notprintable" sm="11">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn @click="refreshAll()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text info" x-large>
                        <v-icon> mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn onclick="window.print()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text primary" x-large>
                        <v-icon> mdi-printer</v-icon>
                    </v-btn>
                    <v-btn @click="emptyAll()" v-if="productlisting.length > 0"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text red" x-large>
                        <v-icon> mdi-cup-outline</v-icon>
                    </v-btn>
                </v-col>

            </v-row>

            <v-row class="text-center">
                <v-col cols="12"
                    class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ma-auto text-center notprintable">
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showImage"
                            :label="langkeyword('IMAGE')"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showQuantity"
                            :label="langkeyword('QUANTITY')"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showDollar"
                            :label="langkeyword('DOLLAR')"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showDinar"
                            :label="langkeyword('DINAR')"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showBarcode"
                            :label="langkeyword('BARCODE')"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text" v-model="showZeroQuantity"
                            label="تەواوبوو"></v-checkbox></v-btn>
                    <v-btn class="ma-1 bg-main"><v-checkbox color="white" class="white--text"
                            v-model="showNoneZeroQuantity" label="تەواونەبوو"></v-checkbox></v-btn>
                </v-col>
            </v-row>

            <v-row class="text-center">

                <v-col cols="12" class="col-11 col-sm-11 col-md-5 col-lg-5 col-xl-5 mx-auto text-center notprintable">
                    <div class="pa-3 bg-mode overflow-y-auto"
                        style="border:1px solid black;border-radius:1rem;height:900px;">
                        <v-row>
                            <div class="col-5 col-sm-4 col-md-3 col-lg-2 col-xl-2 pa-1 ma-auto"
                                v-for="(item, index) in productcategories" :key="index">
                                <v-btn class="bg-main pa-3 ma-1 white--text border-0" style="width:100%;"
                                    v-on:click="addProductCategory(item.id)" v-if="checkProductCategory(item.id) > 0">
                                    <span v-if="lang() === 'ku'">{{ item.ku_name
                                        }}</span>
                                    <span v-if="lang() === 'ar'">{{ item.ar_name
                                        }}</span>
                                    <span v-if="lang() === 'en'">{{ item.en_name
                                        }}</span>
                                </v-btn>
                            </div>
                        </v-row>
                        <br>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <v-divider></v-divider>
                        <br>
                        <div class="">

                            <v-text-field autofocus clearable filled class="nzar-font ma-auto col-11" rounded outlined
                                append-icon="mdi-magnify" v-model="search" :label="langkeyword('SEARCH')">
                            </v-text-field>
                            <v-item-group>
                                <v-container>
                                    <v-row>
                                        <v-col v-for="(item, index) in products" :key="index" cols="6" md="6" lg="4"
                                            xl="3">
                                            <v-item>
                                                <v-card style="cursor: pointer;" v-on:click="addProduct(item.id)"
                                                    elevation="3" height="200">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="mb-1 text-wrap">
                                                                <span v-if="lang() === 'ku'">{{
                                                                    item.ku_name }}</span>
                                                                <span v-if="lang() === 'ar'">{{
                                                                    item.ar_name }}</span>
                                                                <span v-if="lang() === 'en'">{{
                                                                    item.en_name }}</span>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-row>
                                                        <v-card-actions class="col-10 text-center ma-auto">
                                                            <v-img height="100px" style="object-fit:contain;"
                                                                width="200px" class="mx-auto rounded-lg"
                                                                :src="uploadPath(item.avatar)"></v-img>
                                                        </v-card-actions>
                                                    </v-row>
                                                </v-card>
                                            </v-item>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-item-group>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mx-auto text-center pa-1">
                    <v-simple-table>
                        <template>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        {{ langkeyword('NAME') }}
                                    </th>
                                    <th v-if="showBarcode" class="text-center">
                                        {{ langkeyword('BARCODE') }}
                                    </th>
                                    <th v-if="showDollar" class="text-center">
                                        {{ langkeyword('PRICE') }} <span v-if="showDinar">- {{ langkeyword('DOLLAR')
                                            }}</span>
                                    </th>
                                    <th v-if="showDinar" class="text-center">
                                        {{ langkeyword('PRICE') }} <span v-if="showDollar">- {{ langkeyword('DINAR')
                                            }}</span>
                                    </th>
                                    <th v-if="showQuantity" class="text-center">
                                        {{ langkeyword('QUANTITY') }}
                                    </th>
                                    <th v-if="showImage" class="text-center">
                                        {{ langkeyword('IMAGE') }}
                                    </th>
                                    <th class="notprintable"></th>
                                </tr>
                            </thead>
                            <tbody v-for="(product, index) in productlisting.filter(p => {
                                if (showZeroQuantity === true && showNoneZeroQuantity === true) {
                                    return p
                                }
                                else if (showZeroQuantity === true && showNoneZeroQuantity === false) {
                                    return p.qty === 0
                                }
                                else if (showZeroQuantity === false && showNoneZeroQuantity === true) {
                                    return p.qty != 0
                                }
                            })" :key="index">
                                <tr>
                                    <td class="text-wrap">
                                        <span v-if="lang() === 'ku'" class="text-wrap">{{ product.ku_name }}</span>
                                        <span v-if="lang() === 'ar'" class="text-wrap">{{ product.ar_name }}</span>
                                        <span v-if="lang() === 'en'" class="text-wrap">{{ product.en_name }}</span>
                                    </td>
                                    <td v-if="showBarcode">{{ product.barcode }}</td>
                                    <td v-if="showDollar">{{ dollar(product.sale_price_usd) }}</td>
                                    <td v-if="showDinar">{{ dinar(product.sale_price_iqd) }}</td>
                                    <td v-if="showQuantity">{{ product.qty }}</td>
                                    <td v-if="showImage">
                                        <v-img width="70px" height="70px" class="mx-auto rounded-lg"
                                            :src="uploadPath(product.avatar)">
                                        </v-img>
                                    </td>
                                    <td class="notprintable">
                                        <v-icon color="red" @click="deleteProduct(product.id)">mdi-delete</v-icon>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>



            </v-row>
            <v-row class="text-center printable">

            </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            productlisting: [],
            showImage: true,
            showQuantity: true,
            showDollar: true,
            showDinar: true,
            showBarcode: true,
            showZeroQuantity: true,
            showNoneZeroQuantity: true,
            search: '',
        };
    },
    mounted() {
        this.readData();
    },
    computed: {
        products() {
            return this.$store.getters.getproducts.filter(p =>
                !this.$store.getters.getproductlisting.includes(p) && (p.ku_name.search(this.search) > -1 || (p.barcode).toString().search(this.search) > -1)
            )
        },
        productcategories() {
            return this.$store.getters.getproductcategories
        }
    },
    methods: {
        readData() {
            this.productlisting = this.$store.getters.getproductlisting
        },
        addProduct(id) {
            let item = this.products.filter(p => {
                return p.id == id
            })
            this.$store.state.productlisting.push(item[0])
        },
        deleteProduct(id) {
            this.$store.state.productlisting = this.$store.state.productlisting.filter(item => {
                return item.id != id
            })
            this.readData()
        },
        addProductCategory(productCategoryId) {
            this.products.filter(p => {
                return p.productcategory_id == productCategoryId
            }).forEach(item => {
                this.$store.state.productlisting.push(item)
            })
            this.readData()
        },
        checkProductCategory(productCategoryId) {
            return this.products.filter(p => {
                return p.productcategory_id == productCategoryId
            }).length
        },
        emptyAll() {
            this.$store.state.productlisting = []
            this.readData()
        },
        refreshAll() {
            this.search = ''
            this.readData()
        }
    },
};
</script>
<style scoped>
.container,
.container-fluid,
body {
    background: white;
}

@media print {
    .notprintable {
        display: none;
    }

    body,
    template {
        background: white;
    }
}
</style>