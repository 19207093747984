<template>
  <div>
    <navbar></navbar>
    <v-container fluid class="mb-4">



      <v-row class="text-center printable">
        <v-col cols="6 text-start mx-auto" sm="6" md="6" lg="6" xl="6">
          <v-btn small class="info white--text text-h6 pa-4 rounded" @click="printHistoryInvoices($route.params.id)">
            <v-icon>
              mdi-printer
            </v-icon>
            {{ langkeyword('CUSTOMERDISCOVERY') }}
          </v-btn>
        </v-col>
        <v-col cols="6 text-start mx-auto" sm="6" md="6" lg="6" xl="6">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{
                langkeyword("CUSTOMERPROFILE")
              }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword("NAME") }} : {{ customerinfo.name }}</h4>
              <h4>{{ langkeyword("PHONE") }} : {{ customerinfo.phone }}</h4>
              <h4>{{ langkeyword("ADDRESS") }} : {{ customerinfo.address }}</h4>
              <h4>{{ langkeyword("IQDDEBT") }} : {{ dinar(customerinfo.iqddebt) }}</h4>
              <h4>{{ langkeyword("USDDEBT") }} : {{ dollar(customerinfo.usddebt) }}</h4>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(customerinfo.totalDinarSale) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(customerinfo.totalDinarSaleCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(customerinfo.totalDinarSaleDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dinar(customerinfo.totalDinarSaleFree) }}</h4>
            </div>
          </v-card>
        </v-col>

        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(customerinfo.totalDollarSale) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(customerinfo.totalDollarSaleCash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(customerinfo.totalDollarSaleDebt) }}</h4>
              <h4>{{ langkeyword('FREE') }}: {{ dollar(customerinfo.totalDollarSaleFree) }}</h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="4" xl="4">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("PROFIT") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(customerinfo.totaldollarprofit) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(customerinfo.totaldollarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(customerinfo.totaldollarprofitdebt) }}</h4>
              <v-divider></v-divider>
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(customerinfo.totaldinarprofit) }}</h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(customerinfo.totaldinarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(customerinfo.totaldinarprofitdebt) }}</h4>
            </div>
          </v-card>
        </v-col>

      </v-row>







      <v-row class="alert mt-4">
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="error_msg != null">
          <v-alert dense type="warning">{{ error_msg }}</v-alert>
        </v-col>
        <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center" v-if="success_msg != null">
          <v-alert dense type="success">{{ success_msg }}</v-alert>
        </v-col>
      </v-row>





      <v-row class="d-flex text-main justify-center">
        <v-col cols="12 printable">
          <v-data-table :headers="headers" :items="customerreturndebt" :search="search" sort-by="name"
            class="elevation-1 nzar-font radius-15" :footer-props="{
              showFirstLastPage: true,
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': langkeyword('PERPAGE'),
              'items-per-page-options': perPages(),
            }" :items-per-page="10">
            <template v-slot:top>
              <v-toolbar flat>
                <v-toolbar-title><v-icon>mdi-shopping</v-icon>
                  {{ langkeyword("REPAID_DEBT") }}</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="700px" persistent>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn color="success" style="color: white; float: right" class="mb-2 radius-5 nzar-font"
                      v-bind="attrs" v-on="on">
                      <v-icon> mdi-plus-circle </v-icon>
                      {{ langkeyword("REPAYING_DEBT") }}
                    </v-btn>
                  </template>
                  <v-card class="radius-15 bg-mode">
                    <v-card-title>
                      <span class="nzar-font" v-if="isAdd() === true"><v-icon> mdi-plus-circle</v-icon>
                        {{ langkeyword("ADD") }}</span>
                      <span class="nzar-font" v-if="isEdit() === true"><v-icon> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}</span>
                    </v-card-title>
                    <v-card-text class="radius-15">
                      <v-container>
                        <v-form>
                          <v-select v-if="isAdd() === true || isEdit() === true"
                            v-model="customerreturndebtObject.currency_id" :items="currencies"
                            :item-text="langoptions('value') + '_name'" item-value="id"
                            :label="langkeyword('CURRENCIES')" dense></v-select>

                          <v-text-field type="date" class="nzar-font" v-model="customerreturndebtObject.date"
                            :label="langkeyword('DATE')">
                          </v-text-field>

                          <v-text-field type="number" min="0" step="any" class="nzar-font"
                            v-model="customerreturndebtObject.amount" :label="langkeyword('AMOUNT')">
                          </v-text-field>
                        </v-form>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn class="bg-main white--text" v-if="isAdd() === true" dark @click="saveCustomerReturnDebt">
                        <v-icon dark> mdi-plus-circle</v-icon>
                        {{ langkeyword("SAVE") }}
                      </v-btn>
                      <v-btn color="orange darken-1" v-if="isEdit() === true" dark @click="
                        updateCustomerReturnDebt(customerreturndebtObject.id)
                        ">
                        <v-icon dark> mdi-pencil-circle</v-icon>
                        {{ langkeyword("UPDATE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn color="red darken-1" dark @click="closeMainDialog">
                        <v-icon dark>mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <v-dialog persistent v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>
                      <v-icon>mdi-delete-circle</v-icon>
                      {{ langkeyword("DELETE") }}
                    </v-card-title>
                    <v-card-text>
                      {{ langkeyword("ARE_YOU_SURE_YOU_WANT_TO_DELETE") }}
                    </v-card-text>
                    <v-card-actions>
                      <v-btn color="red lighten-2" dark @click="
                        deleteCustomerReturnDebt(customerreturndebtObject.id)
                        ">
                        <v-icon>mdi-delete-circle</v-icon>
                        {{ langkeyword("DELETE") }}
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn justify="end" color="red darken-2" dark @click="closeDeleteDialog">
                        <v-icon> mdi-close-circle</v-icon>
                        {{ langkeyword("CLOSE") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

                <v-spacer></v-spacer>

                <v-row>
                  <v-col cols="12 text-center mx-auto" md="10">
                    <v-text-field class="nzar-font" v-model="search" append-icon="mdi-magnify"
                      :label="langkeyword('SEARCH')" hide-details></v-text-field>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <!--  -->
            <template v-slot:[`item.edit`]="{ item }">
              <v-icon small class="mr-2 info white--text px-2 py-2 rounded" @click="printInvoice(item)">
                mdi-printer
              </v-icon>
              <v-icon small class="mr-2 orange white--text px-2 py-2 rounded" @click="editDialog(item)">
                mdi-pencil-circle
              </v-icon>
              <v-icon small class="mr-2 red white--text px-2 py-2 rounded" @click="deleteDialog(item)">
                mdi-delete-circle
              </v-icon>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>

    <customerInvoicesByCustomer :customer_id="$route.params.id" />

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import CustomersRequests from "../../../requests/dashboard/debt/Customers";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
import customerInvoicesByCustomer from "../../dashboard/sale/customerinvoicesbycustomer.vue";
export default {
  components: {
    navbar,
    customerInvoicesByCustomer,
    dashboardFooter,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      snackbar: true,
      customerinfo: {},
      customerreturndebt: Vue.prototype.getters().getcustomerreturndebt,
      search: "",
      error_msg: null,
      success_msg: null,
      headers: [
        { text: "#", value: "id", align: "center" },
        {
          text: Vue.prototype.langkeyword("CURRENCIES"),
          value: "currencies_" + Vue.prototype.langoptions("value") + "_name",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("AMOUNT"),
          value: "amount",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("DATE"),
          value: "date",
          align: "center",
        },
        {
          text: Vue.prototype.langkeyword("ACTIONS"),
          value: "edit",
          sortable: false,
          align: "center",
        },
      ],
      CURRENTFORM: "add",
      customerreturndebtObject: {
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        customer_id: this.$route.params.id,
        currency_id: 2,
        date: "",
        amount: "",
      },
    };
  },
  watch: {
    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
  },
  computed: {
    currencies() {
      return this.$store.getters.getcurrencies;
    },
  },
  methods: {
    isAdd() {
      if (this.CURRENTFORM === "add") {
        return true;
      } else {
        return false;
      }
    },
    isEdit() {
      if (this.CURRENTFORM === "edit") {
        return true;
      } else {
        return false;
      }
    },
    cleanMessages() {
      setTimeout(() => {
        this.success_msg = null;
        this.error_msg = null;
      }, 3000);
    },
    validate(params, callback) {
      this.error_msg = null;
      params.forEach((param) => {
        if (param == "" || param == undefined || param == null) {
          this.error_msg = this.langkeyword("DATA_REQUIRED");
        }
      });
      if (this.error_msg == null) {
        return callback();
      }
      this.cleanMessages();
    },
    editDialog(item) {
      this.CURRENTFORM = "edit";
      this.customerreturndebtObject = Object.assign({}, item);
      this.dialog = true;
    },
    lockDialog(item) {
      this.CURRENTFORM = "lock";
      this.customerreturndebtObject = Object.assign({}, item);
      this.dialog = true;
    },
    deleteDialog(item) {
      this.CURRENTFORM = "delete";
      this.customerreturndebtObject = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeMainDialog() {
      this.dialog = false;
      this.$nextTick(() => {
        this.customerreturndebtObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    closeDeleteDialog() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.customerreturndebtObject = Object.assign({}, this.defaultItem);
        this.CURRENTFORM = "add";
      });
    },
    saveCustomerReturnDebt() {
      this.validate(
        [
          this.$route.params.id,
          this.customerreturndebtObject.currency_id,
          this.customerreturndebtObject.date,
          this.customerreturndebtObject.amount,
        ],
        () => {
          CustomersRequests.returnDebt(
            {
              returnDebt: true,
              customer_id: this.$route.params.id,
              currency_id: this.customerreturndebtObject.currency_id,
              date: this.customerreturndebtObject.date,
              amount: this.customerreturndebtObject.amount,
              user_id: Vue.prototype.dashboardAuthId(),
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.readData();
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    updateCustomerReturnDebt(customerreturndebtId) {
      this.validate(
        [
          this.$route.params.id,
          this.customerreturndebtObject.currency_id,
          this.customerreturndebtObject.date,
          this.customerreturndebtObject.amount,
        ],
        () => {
          CustomersRequests.editReturnDebt(
            {
              editReturnDebt: true,
              customerreturndebt_id: customerreturndebtId,
              customer_id: this.$route.params.id,
              currency_id: this.customerreturndebtObject.currency_id,
              date: this.customerreturndebtObject.date,
              amount: this.customerreturndebtObject.amount,
            },
            (res) => {
              if (res.data.status === false) {
                this.error_msg = this.langkeyword(res.data.msg);
              } else if (res.data.status === true) {
                this.error_msg = null;
                this.success_msg = this.langkeyword(res.data.msg);
                this.readData();
              }
              this.cleanMessages();
            }
          );
        }
      );
      this.closeMainDialog();
    },
    deleteCustomerReturnDebt(customerreturndebtId) {
      CustomersRequests.deleteReturnedDebt(
        {
          deleteReturnedDebt: true,
          customerreturndebt_id: customerreturndebtId,
          customer_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.success_msg = this.langkeyword(res.data.msg);
            this.readData();
          }
        }
      );
      this.cleanMessages();
      this.closeDeleteDialog();
    },
    readData() {
      CustomersRequests.getOne(
        {
          getOneCustomers: true,
          customer_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;

            if ((this.$store.state.dashboardauth.role_id != 1 && this.$store.state.dashboardauth.role_id != 2)
              && res.data.data.user_id != this.$store.state.dashboardauth.id) {
              setTimeout(() => {
                this.$router.push("/*");
              }, 1000);
            }

            this.customerinfo = res.data.data;
          }
          this.cleanMessages();
        }
      ),
        CustomersRequests.getAllReturnDebt(
          {
            getAllReturnDebt: true,
            customer_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === true) {
              this.error_msg = null;
              this.customerreturndebt = res.data.data;
            }
            this.cleanMessages();
          }
        );
      setTimeout(() => {
        if (!this.permissionExist("#view_customers")) {
          this.$router.push("/dashboard/home");
        }
      }, 500);
    },
    printInvoice(item) {
      this.$router.push(
        "/dashboard/sale/customers/printreturndebt/" +
        item.customer_id +
        "/" +
        item.id
      );
    },
    printHistoryInvoices(customer_id) {
      this.$router.push(
        "/dashboard/sale/customers/printhistory/" + customer_id
      );
    },
  },
};
</script>
<style scoped>
@media print {
  .notprintable {
    display: none;
  }

  .printable {
    display: block;
  }
}
</style>