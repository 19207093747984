<template>
    <div>
        <v-container fluid class="mb-4" :dir="lang() === 'en' ? 'ltr' : 'rtl'">
            <v-row class="text-center">
                <v-col cols="12 text-start mx-auto notprintable" sm="11">
                    <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
                        class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
                        <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
                        <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
                    </v-btn>
                    <v-btn @click="refreshAll()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text info" large>
                        <v-icon> mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn @click="printInvoice()" v-if="fastsalelist.length > 0"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text primary" large>
                        <v-icon> mdi-printer</v-icon>
                    </v-btn>
                    <v-btn @click="saveInvoice()" v-if="fastsalelist.length > 0"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text success" large>
                        <v-icon> mdi-invoice</v-icon>
                    </v-btn>
                    <v-btn @click="returnInvoice()" v-if="fastsalelist.length > 0"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text warning" large>
                        <v-icon> mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="emptyAll()" v-if="fastsalelist.length > 0"
                        :style="lang() === 'en' ? 'float:left' : 'float:right'"
                        class="mb-2 mr-2 radius-5 nzar-font white--text red" large>
                        <v-icon> mdi-cup-outline</v-icon>
                    </v-btn>
                </v-col>

                <v-row class="alert mt-4">
                    <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                        v-if="error_msg != null">
                        <v-alert dense type="warning">{{ error_msg }}</v-alert>
                    </v-col>
                    <v-col class="col-11 col-sm-11 col-md-10 col-lg-10 col-xl-10 mx-auto text-center"
                        v-if="success_msg != null">
                        <v-alert dense type="success">{{ success_msg }}</v-alert>
                    </v-col>
                </v-row>


            </v-row>
            <v-row class="text-center">
                <v-col cols="12" class="col-11 col-sm-11 col-md-5 col-lg-5 col-xl-5 mx-auto text-center notprintable">
                    <div class="pa-3 bg-mode overflow-y-auto"
                        style="border:1px solid black;border-radius:1rem;height:800px;">
                        <div class="">
                            <v-text-field autofocus clearable filled class="nzar-font ma-auto col-11" rounded outlined
                                append-icon="mdi-magnify" v-model="search" :label="langkeyword('SEARCH')">
                            </v-text-field>
                            <v-item-group>
                                <v-container>
                                    <v-row>
                                        <v-col v-for="(item, index) in products.filter((p) => {
                                            return p.qty != 0
                                        })" :key="index" cols="6" md="6" lg="4" xl="3">
                                            <v-item>
                                                <v-card style="cursor: pointer;" v-on:click="addProduct(item.id)"
                                                    elevation="3" height="200">
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title class="mb-1 text-wrap"
                                                                style="font-size:0.9rem">
                                                                <span v-if="lang() === 'ku'">{{
                                                                    item.ku_name }}</span>
                                                                <span v-if="lang() === 'ar'">{{
                                                                    item.ar_name }}</span>
                                                                <span v-if="lang() === 'en'">{{
                                                                    item.en_name }}</span>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                    <v-row>
                                                        <v-card-actions class="col-10 text-center ma-auto">
                                                            <v-img style="height:60px;object-fit:contain;"
                                                                class="mx-auto rounded-lg"
                                                                :src="uploadPath(item.avatar)"></v-img>
                                                        </v-card-actions>
                                                    </v-row>
                                                    <v-chip color="success" class="pa-2 mt-3">
                                                        {{ item.realqty }}
                                                    </v-chip>
                                                </v-card>
                                            </v-item>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-item-group>
                        </div>
                    </div>
                </v-col>

                <v-col cols="12" class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7 mx-auto text-center pa-1">
                    <v-simple-table class="printtable">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    {{ langkeyword('NAME') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('PRICE') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('QUANTITY') }}
                                </th>
                                <th class="text-center">
                                    {{ langkeyword('TOTAL') }}
                                </th>
                                <th class="notprintable text-center">
                                    {{ langkeyword('ACTIONS') }}
                                </th>
                            </tr>
                        </thead>
                        <tbody v-for="(product, index) in fastsalelist" :key="index">
                            <tr>
                                <td class="text-wrap">
                                    <span v-if="lang() === 'ku'" class="text-wrap">{{ product.ku_name }}</span>
                                    <span v-if="lang() === 'ar'" class="text-wrap">{{ product.ar_name }}</span>
                                    <span v-if="lang() === 'en'" class="text-wrap">{{ product.en_name }}</span>
                                </td>
                                <td>{{ dinar(product.sale_price_iqd) }}</td>
                                <td>{{ product.qty }}</td>
                                <td>{{ dinar(product.qty * product.sale_price_iqd) }}</td>
                                <td class="notprintable">
                                    <v-btn color="red" class="ma-1 white--text" x-small
                                        @click="deleteProduct(product.id)"><v-icon>mdi-delete</v-icon></v-btn>
                                    <v-btn color="success" class="ma-1 white--text" x-small
                                        @click="plusQty(product.id)"><v-icon>mdi-plus</v-icon></v-btn>
                                    <v-btn color="warning" class="ma-1 white--text" x-small
                                        @click="minusQty(product.id)"><v-icon>mdi-minus</v-icon></v-btn>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                    <v-simple-table class="pa-3 text-center">
                        <thead>
                            <tr>
                                <th class="text-center ma-auto">
                                    <h2>{{ langkeyword('ALLTOTAL') }}:</h2>
                                </th>
                                <th class="text-center ma-auto">
                                    <h2>{{ totalInvoice() }}</h2>
                                </th>
                            </tr>
                        </thead>
                    </v-simple-table>
                </v-col>



            </v-row>
            <v-row class="text-center printable">

            </v-row>
        </v-container>
    </div>
</template>

<script>
import Vue from 'vue';
import FastSaleRequests from "../../../requests/dashboard/sale/FastSale";
export default {
    data() {
        return {
            fastsalelist: [],
            search: '',
            error_msg: null,
            success_msg: null,
        };
    },
    mounted() {
        this.readData();
    },
    computed: {
        products() {
            return this.$store.getters.getproducts.filter(p =>
                !this.$store.getters.getfastsalelist.includes(p) && (p.ku_name.search(this.search) > -1 || (p.barcode).toString().search(this.search) > -1)
            )
        },
        productcategories() {
            return this.$store.getters.getproductcategories
        }
    },
    methods: {
        readData() {
            this.fastsalelist = this.$store.getters.getfastsalelist
        },
        addProduct(id) {
            this.cleanMessages();
            let item = this.products.filter(p => {
                return p.id == id
            })
            let newItem = item[0]
            this.$store.state.fastsalelist.push(newItem)
            this.$store.state.fastsalelist.filter(p => {
                return p.id == id
            })[0].qty = 1
        },
        deleteProduct(id) {
            this.cleanMessages();
            this.$store.state.fastsalelist = this.$store.state.fastsalelist.filter(item => {
                return item.id != id
            })
            this.readData()
        },
        plusQty(id) {
            let oldItem = this.$store.state.fastsalelist.filter(item => {
                return item.id === id
            })[0]
            let newQty = oldItem.qty + 1
            this.$store.state.fastsalelist.filter(item => {
                return item.id === id
            })[0].qty = newQty
        },
        minusQty(id) {
            let oldItem = this.$store.state.fastsalelist.filter(item => {
                return item.id === id
            })[0]
            let newQty = oldItem.qty - 1
            if (newQty > 0) {
                this.$store.state.fastsalelist.filter(item => {
                    return item.id === id
                })[0].qty = newQty
            }
            else {
                this.deleteProduct(id)
            }
        },
        emptyAll() {
            this.$store.state.fastsalelist = []
            this.readData()
        },
        refreshAll() {
            this.search = ''
            this.readData()
        },
        totalInvoice() {
            return this.dinar(
                this.fastsalelist.reduce(function (prev, p) {
                    return prev + (p.sale_price_iqd * p.qty);
                }, 0)
            )
        },
        saveInvoice() {
            FastSaleRequests.save(
                {
                    saveFastSale: true,
                    invoicenumber: Vue.prototype.randnumber(),
                    user_id: Vue.prototype.dashboardAuthId(),
                    items: this.fastsalelist
                },
                (res) => {
                    if (res.data.status === false) {
                        this.error_msg = this.langkeyword(res.data.msg);
                    } else if (res.data.status === true) {
                        this.error_msg = null;
                        this.success_msg = this.langkeyword(res.data.msg);
                        this.$store.state.customerinvoiceitems = res.data.customerinvoiceitems;
                        this.$store.state.customerinvoices = res.data.customerinvoices;
                        this.$store.state.products = res.data.products;
                        this.readData()
                        this.refreshAll()
                        this.emptyAll()
                    }
                    this.cleanMessages();
                }
            );
        },
        returnInvoice() {
            FastSaleRequests.return(
                {
                    returnFastSale: true,
                    invoicenumber: Vue.prototype.randnumber(),
                    user_id: Vue.prototype.dashboardAuthId(),
                    items: this.fastsalelist
                },
                (res) => {
                    this.error_msg = null;
                    this.success_msg = this.langkeyword(res.data.msg);
                    this.$store.state.customerinvoiceitems = res.data.customerinvoiceitems;
                    this.$store.state.customerinvoices = res.data.customerinvoices;
                    this.$store.state.products = res.data.products;
                    this.readData()
                    this.refreshAll()
                    this.emptyAll()
                    this.cleanMessages();
                }
            );
        },
        printInvoice() {
            window.print()
            this.saveInvoice()
        },
        cleanMessages() {
            setTimeout(() => {
                this.success_msg = null;
                this.error_msg = null;
            }, 3000);
        },
    },
};
</script>
<style scoped>
.container,
.container-fluid,
body {
    background: white;
}

.printtable {
    height: 600px;
    overflow-y: auto;
    overflow-x: auto;
}

@media print {
    .printtable {
        height: auto;
        overflow: hidden;
    }

    .notprintable {
        display: none;
    }

    body,
    template {
        background: white;
    }
}
</style>