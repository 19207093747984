<template>
  <div>
    <navbar></navbar>
    <v-container class="mt-4 mb-4 p-4">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="mx-auto">
          <center>
            <h1 class="mb-5 mt-5">{{ langkeyword("REPORT") }}</h1>
          </center>

          <v-expansion-panels :mandatory="dates.date1 != null && dates.date2 != null" popout hover focusable>
            <v-expansion-panel class="mode" style="border-radius:50px;">
              <v-expansion-panel-header class="mode" style="border-radius:50px;" v-slot="{ open }">
                <v-row no-gutters style="border-radius:50px;">
                  <v-col cols="4" style="border-radius:50px;">
                    {{ langkeyword("REPORT") }}
                  </v-col>
                  <v-col cols="8" style="border-radius:50px;" class="text--secondary">
                    <v-fade-transition leave-absolute>
                      <span v-if="open">{{ langkeyword("SELECTDATES") }}</span>
                      <v-row v-else no-gutters style="width: 100%">
                        <v-col cols="6">
                          {{ langkeyword("DATE1") }}: {{ dates.date1 || "" }}
                        </v-col>
                        <v-col cols="6">
                          {{ langkeyword("DATE2") }}: {{ dates.date2 || "" }}
                        </v-col>
                      </v-row>
                    </v-fade-transition>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="mode" style="border-radius:50px;">
                <v-row justify="space-around" no-gutters>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date1Menu" :close-on-content-click="false" :return-value.sync="dates.date1" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled v-model="dates.date1" :label="langkeyword('DATE1')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date1Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date1Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-menu ref="date2Menu" :close-on-content-click="false" :return-value.sync="dates.date2" offset-y
                      min-width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field filled v-model="dates.date2" :label="langkeyword('DATE2')"
                          prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker v-model="date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.date2Menu.isActive = false">
                          {{ langkeyword("CANCEL") }}
                        </v-btn>
                        <v-btn text color="primary" @click="$refs.date2Menu.save(date)">
                          {{ langkeyword("OK") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="company_id" :items="companies" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-domain" :label="langkeyword('COMPANY')" dense clearable filled></v-select>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="customer_id" :items="customers" item-text="name" item-value="id"
                      prepend-icon="mdi mdi-face-agent" :label="langkeyword('CUSTOMER')" dense clearable
                      filled></v-select>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="product_id" :items="products" :item-text="langoptions('value') + '_name'"
                      item-value="id" prepend-icon="mdi mdi-shopping" :label="langkeyword('PRODUCT')" dense clearable
                      filled></v-select>
                  </v-col>
                  <v-col cols="11" sm="10" md="6" lg="6" xl="6" class="ma-auto text-center m-2 pa-2">
                    <v-select v-model="user_id" :items="users"
                      :disabled="$store.state.dashboardauth.id != 1 && $store.state.dashboardauth.id != 2"
                      :item-text="'name'" item-value="id" prepend-icon="mdi mdi-shopping" :label="langkeyword('USERS')"
                      dense clearable filled></v-select>
                  </v-col>
                </v-row>

                <center>
                  <v-btn @click="saveReport" v-if="dates.date1 != null &&
                    dates.date2 != null &&
                    permissionExist('#search_generalreports')
                  " class="m-auto bg-main text-center white--text">
                    {{ langkeyword("GETREPORT") }}
                  </v-btn>
                </center>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-if="reportCards != false">


      <v-row no-gutters>






        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarbuy) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuy }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarbuycash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuycash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuydebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarbuyfree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarbuyfree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BUY") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarbuy) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuy }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarbuycash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuycash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuydebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarbuyfree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarbuyfree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>








        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarexpense) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpense }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarexpensecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarexpensedebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensedebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarexpensefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarexpensefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("EXPENSE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarexpense) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpense }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarexpensecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarexpensedebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensedebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarexpensefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarexpensefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>










        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DINAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarsale) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsale }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarsalecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsalecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsaledebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dinar(reportData.totaldinarsalefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarsalefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("SALE") }}-{{ langkeyword("DOLLAR") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarsale) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsale }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarsalecash) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsalecash }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsaledebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('FREE') }}: {{ dollar(reportData.totaldollarsalefree) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarsalefree }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>







        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("RETURNDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarreturnbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarreturnbuydebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarreturnbuydebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarreturnbuydebt }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("RETURNDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarreturnsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdollarreturnsaledebt }}
                </v-chip>
              </h4>
              <h4>
                {{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarreturnsaledebt) }}
                <v-chip class="ma-2 white--text" rounded small color="red lighten-2">
                  {{ reportData.countdinarreturnsaledebt }}
                </v-chip>
              </h4>
            </div>
          </v-card>
        </v-col>













        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BASICDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(totalDollarBuyBasicDebt) }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(totalDinarBuyBasicDebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>




        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("BASICDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(totalDollarSaleBasicDebt) }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(totalDinarSaleBasicDebt) }}
              </h4>
            </div>
          </v-card>
        </v-col>







        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("TOTALDEBT") }}-{{ langkeyword("BUY") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarBuyBasicDebt +
                  reportData.totaldollarbuydebt)
                }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarBuyBasicDebt + reportData.totaldinarbuydebt)
                }}
              </h4>
            </div>
          </v-card>
        </v-col>


        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="3" shaped outlined class="ma-1">
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("TOTALDEBT") }}-{{ langkeyword("SALE") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>
                {{ langkeyword('DOLLAR') }}: {{ dollar(reportData.totalDollarSaleBasicDebt +
                  reportData.totaldollarsaledebt)
                }}
              </h4>
              <h4>
                {{ langkeyword('DINAR') }}: {{ dinar(reportData.totalDinarSaleBasicDebt + reportData.totaldinarsaledebt)
                }}
              </h4>
            </div>
          </v-card>
        </v-col>



        <v-col cols="12 text-justify" sm="6" md="4" lg="3" xl="3">
          <v-card elevation="2" outlined shaped>
            <v-card-title class="mx-auto">
              <h3>{{ langkeyword("PROFIT") }}</h3>
            </v-card-title>
            <div class="pa-2">
              <h4>{{ langkeyword('TOTAL') }}: {{ dollar(reportData.totaldollarprofitcash +
                reportData.totaldollarprofitdebt)
                }}
              </h4>
              <h4>{{ langkeyword('CASH') }}: {{ dollar(reportData.totaldollarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dollar(reportData.totaldollarprofitdebt) }}</h4>
              <v-divider></v-divider>
              <h4>{{ langkeyword('TOTAL') }}: {{ dinar(reportData.totaldinarprofitcash +
                reportData.totaldinarprofitdebt) }}
              </h4>
              <h4>{{ langkeyword('CASH') }}: {{ dinar(reportData.totaldinarprofitcash) }}</h4>
              <h4>{{ langkeyword('DEBT') }}: {{ dinar(reportData.totaldinarprofitdebt) }}</h4>
            </div>
          </v-card>
        </v-col>




      </v-row>




































































      <v-row no-gutters class="d-none">
        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{
                    langkeyword("BUY")
                  }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuy) }} -
                    {{ langkeyword("TOTAL") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarbuy }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuy) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarbuy }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuycash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarbuycash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuydebt) }} -
                    {{ langkeyword("DEBT") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarbuyfree) }} -
                    {{ langkeyword("FREE") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarbuyfree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuycash) }} -
                    {{ langkeyword("CASH") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarbuycash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuydebt) }} -
                    {{ langkeyword("DEBT") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarbuyfree) }} -
                    {{ langkeyword("FREE") }}

                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarbuyfree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{
                    langkeyword("EXPENSE")
                  }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpense) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarexpense }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpense) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarexpense }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarexpensecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensedebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarexpensedebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarexpensefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarexpensefree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarexpensecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensedebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarexpensedebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarexpensefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarexpensefree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{
                    langkeyword("SALE")
                  }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsale) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarsale }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsale) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarsale }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsalecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarsalecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsaledebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarsalefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarsalefree }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsalecash) }} -
                    {{ langkeyword("CASH") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarsalecash }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsaledebt) }} -
                    {{ langkeyword("DEBT") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarsalefree) }} -
                    {{ langkeyword("FREE") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarsalefree }}</v-btn>
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>

        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-2" style="font-size:2rem;font-style: normal;">
                    {{ langkeyword("RETURNDEBT") }} ــ {{ langkeyword("BUY") }}
                  </v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarreturnbuydebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarreturnbuydebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarreturnbuydebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarreturnbuydebt }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-2" style="font-size:2rem;font-style: normal;">
                    {{ langkeyword("RETURNDEBT") }} ــ
                    {{ langkeyword("SALE") }}</v-icon>
                </v-list-item-title>

                <div class="text-center">
                  <v-divider></v-divider>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarreturnsaledebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdollarreturnsaledebt }}</v-btn>
                  </v-chip>

                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarreturnsaledebt) }} -
                    {{ langkeyword("TOTAL") }}
                    <v-btn class="ma-2 white--text" rounded small color="red lighten-2">{{
                      reportData.countdinarreturnsaledebt }}</v-btn>
                  </v-chip>

                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>




        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("BASICDEBT")
                    }}
                    ــ {{ langkeyword("BUY") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarBuyBasicDebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarBuyBasicDebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>


            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("BASICDEBT")
                    }}
                    ــ {{ langkeyword("SALE") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarSaleBasicDebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarSaleBasicDebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

          </v-card>
        </v-col>






        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">

            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("TOTALDEBT")
                    }}
                    ــ {{
                      langkeyword("BUY") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">

                    {{ dollar(reportData.totalDollarBuyBasicDebt + reportData.totaldollarbuydebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarBuyBasicDebt + reportData.totaldinarbuydebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>


            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("TOTALDEBT")
                    }}
                    ــ {{
                      langkeyword("SALE") }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totalDollarSaleBasicDebt + reportData.totaldollarsaledebt) }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totalDinarSaleBasicDebt + reportData.totaldinarsaledebt) }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>

          </v-card>
        </v-col>





        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("PROFIT")
                    }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarprofitcash + reportData.totaldollarprofitdebt) }} - {{
                      langkeyword("TOTAL") }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarprofitcash + reportData.totaldinarprofitdebt) }} - {{
                      langkeyword("TOTAL")
                    }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
                <div class="text-center">
                  <v-divider></v-divider>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarprofitcash) }} - {{ langkeyword("CASH") }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarprofitcash) }} - {{ langkeyword("CASH") }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
                <div class="text-center">
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dollar(reportData.totaldollarprofitdebt) }} - {{ langkeyword("DEBT") }}
                  </v-chip>
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ dinar(reportData.totaldinarprofitdebt) }} - {{ langkeyword("DEBT") }}
                  </v-chip>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>









        <v-col cols="12" sm="11" md="10" lg="6" xl="6" class="ma-auto">
          <v-card elevation="1" shaped class="pa-2 mx-1 mt-2 py-1">
            <v-list-item dense>
              <v-list-item-content>
                <v-list-item-title class="text-center ma-auto mb-2">
                  <v-icon class="text-main pa-4" style="font-size:2rem;font-style: normal;">{{ langkeyword("PRODUCT")
                    }}</v-icon>
                </v-list-item-title>
                <div class="text-center">
                  <v-chip class="pa-5 ma-2 white--text bg-main" style="font-size:1.3rem;">
                    {{ reportData.countproductsale }} - {{
                      langkeyword("QUANTITY") }}
                  </v-chip>
                  <v-divider></v-divider>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>




      </v-row>
    </v-container>

    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>
<script>
import GeneralReportRequests from "../../../requests/dashboard/accounting/GeneralReport";
import navbar from "../../../layouts/dashboard/nav.vue";
import dashboardFooter from "../../../layouts/dashboard/dashboardFooter.vue";
export default {
  components: {
    navbar,
    dashboardFooter,
  },
  data: () => ({
    date: null,
    dates: {
      date1: null,
      date2: null,
    },
    reportCards: false,
    user_id: null,
    product_id: null,
    company_id: null,
    customer_id: null,
    reportData: {},
  }),
  watch: {
    options: {
      handler() { },
    },
  },
  mounted() {
    if (this.$store.state.dashboardauth.role_id != 1 && this.$store.state.dashboardauth.role_id != 1) {
      this.user_id = this.$store.state.dashboardauth.id
    }
    setTimeout(() => {
      if (!this.permissionExist("#view_generalreports")) {
        this.$router.push("/dashboard/home");
      }
      this.getAllReport()
    }, 500);
  },
  computed: {
    companies() {
      return this.$store.getters.getcompanies;
    },
    customers() {
      return this.$store.getters.getcustomers;
    },
    products() {
      return this.$store.getters.getproducts;
    },
    users() {
      return this.$store.getters.getusers.filter(u => {
        return u.id != 1
      });
    },
  },
  methods: {
    saveReport() {
      GeneralReportRequests.byDate(
        {
          byDatePublicReportApp: true,
          date1: this.dates.date1,
          date2: this.dates.date2,
          user_id: this.user_id,
          product_id: this.product_id,
          company_id: this.company_id,
          customer_id: this.customer_id,
        },
        (res) => {
          this.reportCards = true;
          this.reportData = res.data;
        }
      );
    },
    getAllReport() {
      GeneralReportRequests.getAll(
        {
          getAllPublicReportApp: true
        },
        (res) => {
          this.reportCards = true;
          this.reportData = res.data;
          console.log(res.data)
        }
      );
    },
  },
};
</script>
