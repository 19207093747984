<template>
  <div>
    <v-container fluid class="mb-4">
      <v-row class="text-center">
        <v-col cols="12 text-start mx-auto notprintable" sm="11">
          <v-btn @click="$router.go(-1)" :style="lang() === 'en' ? 'float:right' : 'float:left'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text bg-main">
            <v-icon v-if="lang() === 'ku'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'ar'"> mdi-arrow-left</v-icon>
            <v-icon v-if="lang() === 'en'"> mdi-arrow-right</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
          <v-btn onclick="window.print()" :style="lang() === 'en' ? 'float:left' : 'float:right'"
            class="mb-2 mr-2 h-100 radius-5 nzar-font white--text primary" x-large>
            <v-icon> mdi-printer</v-icon>
            {{ langkeyword("BACK") }}
          </v-btn>
        </v-col>
        <v-col cols="12 text-start mx-auto" sm="12" class="printbg white rounded rounded">
          <v-card color="transparent" elevation="0" outlined shaped>
            <v-row>
              <div class="col-8 col-sm-8 col-md-8 col-lg-10 col-xl-10 pa-4 m-auto text-justify">
                <v-card-title class="mx-auto">
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ku'">
                    {{ $store.state.setting.ku_name }}
                  </h2>
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'ar'">
                    {{ $store.state.setting.ar_name }}
                  </h2>
                  <h2 class="dark--text" style="font-size: 3rem" v-if="lang() === 'en'">
                    {{ $store.state.setting.en_name }}
                  </h2>
                </v-card-title>
                <br />
                <v-card-text>
                  <h3 class="dark--text">
                    {{ langkeyword("PHONE") }} :
                    {{ $store.state.setting.phone1 }}
                    -
                    {{ $store.state.setting.phone2 }}
                    <br /><br />
                  </h3>
                  <h3 class="dark--text"
                    v-if="$store.state.setting.ku_address != null && $store.state.setting.ku_address != ''">
                    {{ langkeyword("ADDRESS") }} :
                    <span v-if="lang() === 'en'">{{ $store.state.setting.en_address }}</span>
                    <span v-if="lang() === 'ar'">{{ $store.state.setting.ar_address }}</span>
                    <span v-if="lang() === 'ku'">{{ $store.state.setting.ku_address }}</span>
                    <br /><br />
                  </h3>
                  <h3 class="dark--text" v-if="$store.state.setting.email != null && $store.state.setting.email != ''">
                    {{ langkeyword("EMAIL") }} :
                    {{ $store.state.setting.email }}
                    <br /><br />
                  </h3>
                  <h3 class="dark--text"
                    v-if="$store.state.setting.licensenumber != null && $store.state.setting.licensenumber != ''">
                    {{ langkeyword("LICENSENUMBER") }} :
                    {{ $store.state.setting.licensenumber }}
                    <br /><br />
                  </h3>
                  <h3 class="dark--text"
                    v-if="$store.state.setting.website != null && $store.state.setting.website != ''">
                    {{ langkeyword("WEBSITE") }} :
                    {{ $store.state.setting.website }}
                    <br /><br />
                  </h3>
                </v-card-text>
              </div>
              <div class="col-4 col-sm-4 col-md-4 col-lg-2 col-xl-2 ma-auto m-auto text-end">
                <img class="text-start logo" :src="uploadPath(this.$store.state.setting.logo)
                  " alt="logo" style="width: 200px;height:200px;object-fit:contain;" />
                <hr>
                <h3 class="pa-3">{{ langkeyword("DATE") }} : {{ customerInvoiceDetail("date") }}</h3>
                <h3 class="pa-3">{{ langkeyword("INVOICENUMBER") }} : {{ customerInvoiceDetail("invoicenumber") }}</h3>
              </div>
            </v-row>
            <v-row>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("CUSTOMER") }} : {{ customerInvoiceDetail("customer") }}</h3>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("PHONE") }} : {{ customerInvoiceDetail("phone") }}</h3>
              </div>
              <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 text-center">
                <h3>{{ langkeyword("ADDRESS") }} : {{ customerInvoiceDetail("address") }}</h3>
              </div>
            </v-row>
            <hr />
            <v-row>
              <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 pa-4 ma-auto text-center">
                <v-simple-table dense>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center" style="font-size:1.4rem;">
                          {{ langkeyword("PRODUCT") }}
                        </th>
                        <th class="text-center" style="font-size:1.4rem;">
                          {{ langkeyword("IMAGE") }}
                        </th>
                        <th class="text-center" style="font-size:1.4rem;">
                          {{ langkeyword("PRICE") }}
                        </th>
                        <th class="text-center" style="font-size:1.4rem;">
                          {{ langkeyword("QUANTITY") }}
                        </th>
                        <th class="text-center" style="font-size:1.4rem;">
                          {{ langkeyword("TOTAL") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(cii, index) in customerinvoiceitems" :key="index">
                        <td style="font-size:1.1rem;">
                          <span v-if="lang() === 'en'">{{ cii.products_en_name }}</span>
                          <span v-if="lang() === 'ar'">{{ cii.products_ar_name }}</span>
                          <span v-if="lang() === 'ku'">{{ cii.products_ku_name }}</span>
                        </td>
                        <td>
                          <img class="text-start" :src="uploadPath(cii.products_avatar)" alt="product"
                            style="width: 70px;height:70px;object-fit:contain;" />
                        </td>
                        <td style="font-size:1.1rem;">{{ customerInvoiceDetail("currency_id") === 1 ?
                          dinar(cii.sale_price) : dollar(cii.sale_price) }}</td>
                        <td style="font-size:1.1rem;">{{ cii.qty }}</td>
                        <td style="font-size:1.1rem;">{{ customerInvoiceDetail("currency_id") === 1 ? dinar(cii.qty *
                          cii.sale_price) : dollar(cii.qty * cii.sale_price) }}</td>
                      </tr>
                      <tr :class="invoice.discount == 0 ? 'd-none' : ''">
                        <th style="font-size:1rem;">
                          <h3>{{ langkeyword("DISCOUNT") }}:
                            {{ customerInvoiceDetail("currency_id") === 1 ? dinar(invoice.discount)
                              : dollar(invoice.discount) }}
                          </h3>
                        </th>
                      </tr>
                      <tr :class="invoice.driverwage == 0 ? 'd-none' : ''">
                        <th style="font-size:1rem;">
                          <h3>{{ langkeyword("DRIVERWAGE") }}:
                            {{ customerInvoiceDetail("currency_id") === 1 ? dinar(invoice.driverwage)
                              : dollar(invoice.driverwage) }}
                          </h3>
                        </th>
                      </tr>
                      <tr>
                        <th style="font-size:1.1rem;">
                          <h3>{{ langkeyword("ALLTOTAL") }}: {{ customerInvoiceDetail("currency_id") === 1 ?
                            dinar((totalinvoice - invoice.discount) + invoice.driverwage) : dollar((totalinvoice -
                              invoice.discount) + invoice.driverwage) }}
                          </h3>
                        </th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-row>

            <v-card-body> </v-card-body>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <br /><br /><br />
    <dashboardFooter />
  </div>
</template>

<script>
import Vue from "vue";
import CustomerInvoicesRequests from "../../../requests/dashboard/sale/CustomerInvoices";
import CustomerInvoiceItemsRequests from "../../../requests/dashboard/sale/CustomerInvoiceItems";
export default {
  data() {
    return {
      customerinvoiceitems: Vue.prototype.getters().getcustomerinvoiceitems,
      unittypes: [
        { title: Vue.prototype.langkeyword("UNITONE"), value: "one" },
        { title: Vue.prototype.langkeyword("UNITMANY"), value: "many" },
      ],
      invoice: 0,
      totalinvoice: 0,
      customerinvoiceitemsObject: {
        id: "",
        user_id: Vue.prototype.dashboardAuthId(),
        customerinvoice_id: this.$route.params.id,
        product_id: "",
        qty: "",
        unittype: "",
        sale_price: "",
      },
    };
  },
  watch: {
    /*
          filter: (value) => {
            return value == this.$route.params.id;
          },
*/

    options: {
      handler() {
        this.readData();
      },
    },
  },
  mounted() {
    this.readData();
  },
  computed: {
  },
  methods: {
    customerInvoiceDetail(field) {
      let actField = "";
      if (field === "invoicetype") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.invoicetypes_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.invoicetypes_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.invoicetypes_en_name;
        }
      }
      else if (field === "currency") {
        if (this.langoptions("value") === "ku") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_ku_name;
        } else if (this.langoptions("value") === "ar") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_ar_name;
        } else if (this.langoptions("value") === "en") {
          actField =
            this.$store.state.currentcustomerinvoicedetail.currencies_en_name;
        }
      } else if (field === "date") {
        actField = this.$store.state.currentcustomerinvoicedetail.date;
      } else if (field === "symbol") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.currencies_symbol;
      } else if (field === "invoicenumber") {
        actField = this.$store.state.currentcustomerinvoicedetail.invoicenumber;
      } else if (field === "customer") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_name;
      } else if (field === "phone") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_phone;
      } else if (field === "address") {
        actField =
          this.$store.state.currentcustomerinvoicedetail.customers_address;
      } else if (field === "customer_id") {
        actField = this.$store.state.currentcustomerinvoicedetail.customer_id;
      } else if (field === "invoicetype_id") {
        actField = this.$store.state.currentcustomerinvoicedetail.invoicetype_id;
      } else if (field === "currency_id") {
        actField = this.$store.state.currentcustomerinvoicedetail.currency_id;
      } else if (field === "total") {
        actField = this.$store.state.currentcustomerinvoicedetail.total;
      } else if (field === "avatar") {
        actField = this.$store.state.currentcustomerinvoicedetail.avatar;
      } else if (field === "name") {
        actField = this.$store.state.currentcustomerinvoicedetail.name;
      }
      return actField;
    },
    readData() {
      CustomerInvoicesRequests.getOne(
        {
          getOneCustomerInvoices: true,
          customerinvoice_id: this.$route.params.id,
        },
        (res) => {
          if (res.data.status === false) {
            this.error_msg = this.langkeyword(res.data.msg);
            setTimeout(() => {
              this.$router.push("/*");
            }, 1000);
          } else if (res.data.status === true) {
            this.error_msg = null;
            this.$store.state.currentcustomerinvoicedetail = res.data.data;
          }
        }
      ),
        CustomerInvoiceItemsRequests.getAll(
          {
            getAllCustomerInvoiceItems: true,
            customerinvoice_id: this.$route.params.id,
          },
          (res) => {
            if (res.data.status === true) {
              this.error_msg = null;
              this.$store.state.customerinvoiceitems = res.data.data;
              this.customerinvoiceitems = res.data.data;
              this.invoice = res.data.invoice;
              this.totalinvoice = res.data.totalinvoice;
            }
          }
        );
    },
  },
};
</script>
<style scoped>
body {
  background: white;
}

.container,
.container-fluid {
  background: white;
}

@media print {
  .notprintable {
    display: none;
  }
}

.printbg {
  color: black;
}
</style>